import React from "react";
import { Container } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";

import BannerImg from "../images/review_banner_img.png";

const Banner = loadable(() => import("../components/Banner/Banner"));
const ReviewsSlider = loadable(() => import("../components/ReviewsSlider/ReviewsSlider"));
const StaticDetailPagination = loadable(() => import("../components/StaticDetailPagination/StaticDetailPagination"));
const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));

const Reviews = (props) => {
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <Banner 
                bannerImg={BannerImg}
                tag={"reviews-page"}
            />

            <ReviewsSlider />

            <div className="reviews-wrapper section-p">
                <Container>
                    <div className="reviews-wrapper-main">
                        <StaticDetailPagination />
                    </div>
                </Container>
            </div>

            <PatternBanner 
                tag = "brown"
                title = "start your journey"
                desc= "Our team of estate agents are experienced, passionate and creative people who are well connected in their local community."
            />

        </Layout>
    )
}

export default Reviews